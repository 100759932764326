import React, { useState } from "react";
import { Input, Slider, Card } from "antd";
import styles from "./styles.module.scss";

const DiscountSubscribers: React.FC = () => {
  const [value, setValue] = useState<number>(0);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    setValue(newValue);
  };

  const onSliderChange = (newValue: number) => {
    setValue(newValue);
  };

  return (
    <Card className={styles.card} bodyStyle={{ padding: 0 }}>
      <div className={styles.title}>Discount Subscribers</div>
      <Input
        type="number"
        value={value}
        onChange={onInputChange}
        className={styles.input}
        readOnly
      />
      <Slider
        min={-100}
        max={100}
        value={value}
        onChange={onSliderChange}
        className={styles.slider}
        trackStyle={{ backgroundColor: "#1890ff", height: "8px" }}
        handleStyle={{
          backgroundColor: "#fff",
          borderColor: "#1890ff",
          height: "20px",
          width: "20px",
          marginTop: "-6px",
        }}
        railStyle={{ backgroundColor: "#444", height: "8px" }}
      />
    </Card>
  );
};

export default DiscountSubscribers;
