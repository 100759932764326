import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Settings from "@assets/img/settings";
import { useModalStore } from "src/store/modal.state";
import { useWidgets } from "@hooks/useWidgets";
import { formatValue } from "src/utils/formatDate";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import classNames from "classnames";
import { ConfigModalProduct } from "@components/config-modal-product";
import { useDataBlockMetricStore } from "src/store/metric-block.state";
import { defaultMetrics } from "@components/config-modal-product/mock";

interface DataBlockProps {}

const DataBlock: React.FC<DataBlockProps> = () => {
  const { selectedProduct } = useProductStore();
  const [totalPosRounded, setTotalPosRounded] = useState<string>("N/A");
  const ongoingRequest = useRef<string | null>(null);
  const { handleApply, updatedWidgets } = useWidgets();
  const openModal = useModalStore((state) => state.openModal);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });
  const { selectedMetric } = useDataBlockMetricStore();
  const fetchPos = async () => {
    const asin =
      typeof selectedProduct === "string"
        ? selectedProduct
        : selectedProduct?.asin;
    if (ongoingRequest.current === asin) return;
    ongoingRequest.current = asin;
    try {
      const spider = new SpiderService();
      const spiderDots = await spider.getCroInfoPos(asin);
      const roundedValue = Math.round(spiderDots.total_pos).toString();
      setTotalPosRounded(roundedValue);
    } catch {
      setTotalPosRounded("N/A");
    } finally {
      ongoingRequest.current = null;
    }
  };

  useEffect(() => {
    if (selectedProduct) fetchPos();
  }, [selectedProduct]);

  const mapApiDataToDataBlockProps = (apiData) => {
    const dataLookup = {};
    apiData.forEach((item) => {
      dataLookup[item.name] = item;
    });

    return {
      sessionTotal: dataLookup["Sales Total"]?.value || "N/A",
      pageViewsTotal: dataLookup["Ad Impressions"]?.value || "N/A",
      featuredOffer: dataLookup["Organic Clicks"]?.value || "N/A",
      unitsOrdered: dataLookup["Ad Orders"]?.value || "N/A",
      unitsSessionPercentage: dataLookup["Ad CVR"]?.value || "N/A",
      orderedProductSales: dataLookup["Ad Sales"]?.value || "N/A",
      totalOrderItems: dataLookup["Ad Units Sold"]?.value || "N/A",
      totalCTR: dataLookup["Ad CTR"]?.value || "N/A",
      totalCVR: dataLookup["Ad CVR"]?.value || "N/A",
      totalPos: totalPosRounded,
    };
  };

  const dataBlockProps = mapApiDataToDataBlockProps(updatedWidgets);
  const metricsToDisplay =
    selectedMetric.length > 0 ? selectedMetric : defaultMetrics;

  const openConfigModal = (event: React.MouseEvent) => {
    const { right, bottom, width } = (
      event.currentTarget as HTMLButtonElement
    ).getBoundingClientRect();
    setModalPosition({ x: right, y: bottom, width });
    setIsConfigModalOpen(true);
    openModal();
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
  };

  return (
    <div className={styles.dataBlock}>
      <table className={styles.table}>
        <thead className={styles.headerRow}>
          <tr>
            {metricsToDisplay.map((metric) => (
              <th key={metric.key} className={styles.headerCell}>
                {metric.name}
              </th>
            ))}
            <button className={styles.headerCell} onClick={openConfigModal}>
              <Settings />
            </button>
          </tr>
        </thead>
        <tbody className={styles.dataRow}>
          <tr>
            <td className={styles.dataCell}>
              {" "}
              {formatValue(dataBlockProps.sessionTotal)}
            </td>
            <td className={styles.dataCell}>
              {formatValue(dataBlockProps.totalCVR)}
            </td>
            <td className={styles.dataCell} style={{ color: "#80c67a" }}>
              {formatValue(dataBlockProps.totalCTR)}
            </td>
            <td className={styles.dataCell}>
              {" "}
              {formatValue(dataBlockProps.pageViewsTotal)}
            </td>
            <td className={styles.dataCell} style={{ color: "#fe5858" }}>
              {formatValue(dataBlockProps.featuredOffer)}
            </td>
            <td className={styles.dataCell}>
              {" "}
              {formatValue(dataBlockProps.orderedProductSales)}
            </td>
            <td className={styles.dataCell}>
              {formatValue(dataBlockProps.totalOrderItems)}
            </td>
            <td className={styles.dataCell}>
              {formatValue(dataBlockProps.totalPos)}
            </td>
          </tr>
        </tbody>
      </table>

      {isConfigModalOpen && (
        <div className={styles.overlay} onClick={closeConfigModal}>
          <div
            className={classNames(styles.modalContainer)}
            style={{
              top: modalPosition.y,
              left: modalPosition.x + 20,
              width: modalPosition.width,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <ConfigModalProduct
              buttonWidth={modalPosition.width}
              closeModal={closeConfigModal}
              onApply={handleApply}
              maxSelectedWidgets={8}
              storeType="dataBlock"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DataBlock;
