import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import BottomArrow from "@assets/icons/bottomArrow";
import LoadMoreButton from "@components/LoadMoreButton";
import { Products } from "@pages/Dashboard/mocks/products";
import Settings from "@assets/img/settings";
import ProductCode from "@components/ProductCode";
import ProblemsBlock from "@components/ProblemsBlock";
import { useModalStore } from "src/store/modal.state";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { formattedKeys } from "src/utils/convertUtil";
import { SpiderService } from "@services/spider/spider.services";
import classNames from "classnames";
import { ConfigModalProduct } from "@components/config-modal-product";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { useMetricStore } from "src/store/metrict.state";
import { defaultMetrics } from "@components/config-modal-product/mock";

const Table: React.FC = () => {
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [expandedProductId, setExpandedProductId] = useState<number | null>(
    null,
  );
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const { setSelectedProduct } = useProductStore();
  const [products, setProducts] = useState<ProductWithImageLink[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const productsService = new ProductsService();
  const openModal = useModalStore((state) => state.openModal);
  const [productPosData, setProductPosData] = useState<{
    [asin: string]: string;
  }>({});

  const { widgets } = useDashboardStore((state) => ({
    setWidgets: state.setWidgets,
    widgets: state.widgets,
  }));
  const { selectedMetric, setSelectedMetric } = useMetricStore();
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });

  const metricsToDisplay =
    selectedMetric.length > 0 ? selectedMetric : defaultMetrics;

  const { setProductOneSpiderTitles, setProductOneSpiderValues } =
    useInsightsStore();
  const [selectedAsin, setSelectedAsin] = useState<string | null>(null);
  const fetchedAsins = useRef<Set<string>>(new Set());

  const handleLoadMore = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setLoadingMore(false);
      const newVisibleProducts = visibleProducts + 10;
      setVisibleProducts(newVisibleProducts);
      fetchPosForProducts(products.slice(visibleProducts, newVisibleProducts));
    }, 1000);
  };

  const toggleExpand = (productId: number, product: ProductWithImageLink) => {
    if (expandedProductId === productId) {
      setExpandedProductId(null);
      setSelectedProduct(null);
      setSelectedAsin(null);
    } else {
      setExpandedProductId(productId);
      setSelectedProduct(product.asin);
      setSelectedAsin(product.asin);
    }
  };

  const fetchSpider = async (asin: string) => {
    try {
      const spider = new SpiderService();
      const spiderDots = await spider.getSpiderInfo(asin);
      if (spiderDots && Object.keys(spiderDots).length > 0) {
        setProductOneSpiderTitles(formattedKeys(spiderDots));
        setProductOneSpiderValues(
          Object.values(spiderDots).map((value) =>
            parseFloat(value.toFixed(1)),
          ),
        );
      } else {
        setProductOneSpiderTitles([]);
        setProductOneSpiderValues([]);
      }
    } catch (err) {
      setProductOneSpiderTitles([]);
      setProductOneSpiderValues([]);
    }
  };

  useEffect(() => {
    if (selectedAsin) {
      fetchSpider(selectedAsin);
    } else {
      setProductOneSpiderTitles([]);
      setProductOneSpiderValues([]);
    }
  }, [selectedAsin]);

  const fetchProducts = async (page: number, limit: number = 10) => {
    try {
      const params = { limit, page: 1 };
      const response = await productsService.getOwnProducts(params);
      if (response.length > 0) {
        setProducts(response);

        if (response.length === limit && limit < 32) {
          fetchProducts(page, limit === 8 ? 16 : 32);
        }
      }
      fetchPosForProducts(response.slice(0, 10));
    } catch (err) {
      console.error("Error fetching products:", err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchPosForProducts = (productsBatch: ProductWithImageLink[]) => {
    const spider = new SpiderService();

    productsBatch.forEach(async (product) => {
      if (!fetchedAsins.current.has(product.asin)) {
        try {
          const spiderDots = await spider.getCroInfoPos(product.asin);
          const posValue = Math.round(spiderDots.total_pos).toString();

          setProductPosData((prevPosData) => ({
            ...prevPosData,
            [product.asin]: posValue,
          }));
          fetchedAsins.current.add(product.asin);
        } catch (err) {
          setProductPosData((prevPosData) => ({
            ...prevPosData,
            [product.asin]: "N/A",
          }));
        }
      }
    });
  };

  useEffect(() => {
    if (products.length > 0 && visibleProducts === 10) {
      setVisibleProducts(Math.min(10, products.length));
    }
  }, [products]);
  const openConfigModal = (event: React.MouseEvent) => {
    const { right, bottom, width } = (
      event.currentTarget as HTMLButtonElement
    ).getBoundingClientRect();
    setModalPosition({ x: right, y: bottom, width });
    setIsConfigModalOpen(true);
    openModal();
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
  };
  const handleApplyMetrics = (selectedMetrics: IWidget[]) => {
    setSelectedMetric(selectedMetrics);
    setIsConfigModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.headerCell}>PRODUCT</th>
            {metricsToDisplay.map((metric) => (
              <th key={metric.key} className={styles.headerCell}>
                {metric.name}
              </th>
            ))}
            <button className={styles.headerCell} onClick={openConfigModal}>
              <Settings />
            </button>
          </tr>
        </thead>
        <tbody>
          {products.slice(0, visibleProducts).map((product, index) => {
            const mockData = Products[index % Products.length];
            const posValue = productPosData[product.asin] || "Loading...";

            return (
              <React.Fragment key={index}>
                <tr
                  className={`${styles.row} ${
                    expandedProductId === index ? styles.expanded : ""
                  }`}
                >
                  <td className={styles.cell}>
                    <div className={styles.productInfo}>
                      <img
                        src={
                          product.image_link || "/src/assets/img/noImage.jpg"
                        }
                        alt="Product Image"
                        className={styles.productImage}
                      />
                      <div className={styles.productDetails}>
                        <p>{product.item_name || "No description"}</p>
                        <ProductCode code={product.asin || "N/A"} />
                      </div>
                    </div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.sessionTotal}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.pageViewsTotal}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.featuredOffer}%</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.unitsOrdered}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.unitsSessionPercentage}%</div>
                  </td>
                  <td className={styles.cell}>
                    <div>${mockData.orderedProductSales}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.totalOrderItems}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{posValue}</div>
                  </td>
                  <td className={styles.cell}>
                    <button
                      className={`${styles.expandButton} ${
                        expandedProductId === index ? styles.rotated : ""
                      }`}
                      onClick={() => toggleExpand(index, product)}
                    >
                      <BottomArrow />
                    </button>
                  </td>
                </tr>
                {expandedProductId === index && (
                  <tr className={styles.expandedRow}>
                    <td colSpan={9} className={styles.expandedCell}>
                      <ProblemsBlock topProblems={mockData.topProblems || []} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {visibleProducts < products.length && (
        <LoadMoreButton onClick={handleLoadMore} />
      )}

      {isConfigModalOpen && (
        <div className={styles.overlay} onClick={closeConfigModal}>
          <div
            className={classNames(styles.modalContainer)}
            style={{
              top: modalPosition.y,
              left: modalPosition.x,
              width: modalPosition.width,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <ConfigModalProduct
              buttonWidth={300}
              closeModal={() => setIsConfigModalOpen(false)}
              initialWidgets={widgets}
              setWidgets={() => {}}
              onApply={handleApplyMetrics}
              maxSelectedWidgets={8}
              storeType="table"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
