import { useRef, useState } from "react";
import styles from "./styles.module.scss";
import product1 from "@assets/img/noImage.jpg";
import ProductSelectionModal from "@pages/Overview/components/ProductSelectionModal";
import inputArrow from "@assets/icons/inputArrow.svg";
import { useProductStore } from "src/store/overviewProduct.state";
import { Product } from "@components/Multiselect";
import { OverviewType } from "@pages/Overview";
import classNames from "classnames";
import DiscountSubscribers from "../DiscountSubscribers";
interface ProductInputProps {
  selectType?: "your" | "competitor";
  type?: OverviewType;
  selectedProduct?: Product;
  className: any;
}

const SubscriptionOwn: React.FC<ProductInputProps> = ({
  selectedProduct,
  selectType = "your",
  type = "my-product",
  className,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openFirstModal = () => setModalOpen(true);
  const { selectedProductToCompare } = useProductStore();
  const [hasSelectedOnce, setHasSelectedOnce] = useState(false);
  const prevProductRef = useRef<Product | null>(null);

  const product =
    selectType === "your" ? selectedProduct : selectedProductToCompare;
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleProductSelect = (selectedProduct: Product | Product[]) => {
    if (selectType === "competitor") {
      setHasSelectedOnce(true);
      if (Array.isArray(selectedProduct)) {
        selectedProduct.forEach((product) => {
          prevProductRef.current = product;
        });
      } else {
        prevProductRef.current = selectedProduct;
      }
      setModalOpen(false);
      closeModal();
    }
  };
  return (
    <div className={classNames(styles.reviewScoreContainer, className)}>
      <div className={styles.productBox}>
        <div className={styles.imageContainer}>
          <img
            src={
              selectedProduct?.image_link ||
              selectedProduct?.picture_url ||
              product1
            }
            alt="choose your product"
            className={styles.productImage}
          />
        </div>
        <>
          <button onClick={openFirstModal} className={styles.inputContainer}>
            <input
              className={styles.inputText}
              value={
                product && !Array.isArray(product)
                  ? product.item_name || product.title
                  : ""
              }
              readOnly
            />
            <div className={styles.dropdownIcon}>
              <img
                src={inputArrow}
                width={12}
                height={12}
                alt="Dropdown Icon"
              />
            </div>
          </button>
          <ProductSelectionModal
            isOpen={isModalOpen}
            onSelect={handleProductSelect}
            onClose={closeModal}
            type={type}
          />
        </>
        <DiscountSubscribers />
      </div>
    </div>
  );
};

export default SubscriptionOwn;
