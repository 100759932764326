import { Card } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import styles from "./styles.module.scss";

const data = [
  { month: "January", "1 month": 1200, "3 month": 800, Other: 400 },
  { month: "February", "1 month": 1500, "3 month": 1200, Other: 600 },
  { month: "March", "1 month": 2000, "3 month": 1800, Other: 800 },
  { month: "April", "1 month": 1800, "3 month": 1600, Other: 700 },
  { month: "May", "1 month": 1300, "3 month": 1100, Other: 500 },
  { month: "June", "1 month": 1600, "3 month": 1300, Other: 600 },
  { month: "July", "1 month": 1400, "3 month": 1100, Other: 500 },
  { month: "August", "1 month": 900, "3 month": 700, Other: 300 },
];

const COLORS = ["#1BB08D", "#50DAB9", "#A6FAE6"];

const SubscriptionReport = () => {
  const formatYAxis = (value) => `${value / 1000}K`;

  return (
    <Card
      title="Subscription Reporting"
      style={{
        backgroundColor: "#141414",
        borderRadius: "24px",
        border: "1px solid #2E2E2E",
        width: "100%",
        padding: "16px",
        height: "464px",
        color: "white",
      }}
      headStyle={{
        fontWeight: "bold",
        fontSize: "16px",
        color: "white",
        borderBottom: "none",
      }}
      bodyStyle={{
        padding: "5px",
      }}
    >
      <div className={styles.legendContainer}>
        <div className={styles.legendItem}>
          <span
            className={styles.colorDot}
            style={{ backgroundColor: COLORS[0] }}
          ></span>
          <span className={styles.legendText}>Subscription Count 1 month</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={styles.colorDot}
            style={{ backgroundColor: COLORS[1] }}
          ></span>
          <span className={styles.legendText}>Subscription Count 3 month</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={styles.colorDot}
            style={{ backgroundColor: COLORS[2] }}
          ></span>
          <span className={styles.legendText}>Subscription Count Other</span>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
          barCategoryGap={0}
          barSize={64}
        >
          <CartesianGrid stroke="#1F1F20" vertical={false} />
          <XAxis
            dataKey="month"
            tick={{ fill: "white" }}
            axisLine={{ stroke: "#1F1F20" }}
          />
          <YAxis
            tick={{ fill: "white" }}
            axisLine={{ stroke: "#1F1F20" }}
            tickFormatter={formatYAxis}
            ticks={[0, 2000, 4000, 6000]}
          />
          {/* <Tooltip cursor={{ fill: "transparent" }} />  */}
          <Bar
            dataKey="1 month"
            stackId="a"
            fill={COLORS[0]}
            name="Subscription Count 1 month"
          />
          <Bar
            dataKey="3 month"
            stackId="a"
            fill={COLORS[1]}
            name="Subscription Count 3 month"
          />
          <Bar
            dataKey="Other"
            stackId="a"
            fill={COLORS[2]}
            name="Subscription Count Other"
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SubscriptionReport;
