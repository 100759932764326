export const mockCategories = [
  { id: 1, name: "Total metrics" },
  { id: 2, name: "Ad metrics" },
  { id: 3, name: "Organic metrics" },
  { id: 4, name: "Other metrics" },
];
export const defaultMetrics = [
  { name: "Total sales", key: "sessionTotal" },
  { name: "Total CVR", key: "pageViewsTotal" },
  { name: "Total CTR", key: "featuredOffer" },
  { name: "Total impressions", key: "unitsOrdered" },
  { name: "Total Clicks", key: "unitsSessionPercentage" },
  { name: "Sales difference", key: "orderedProductSales" },
  { name: "Sales with Nyle", key: "totalOrderItems" },
  { name: "Total POS", key: "posValue" },
];
export const ITEMS_PER_PAGE = 5;

export const MOCK_CATEGORIES = [
  { id: 1, name: "Total metrics" },
  { id: 2, name: "Ad metrics" },
  { id: 3, name: "Organic metrics" },
  { id: 4, name: "Other metrics" },
];
